<template>
  <section class="field-styling-drawer">
    <div v-if="field.input_type === 'HORIZONTAL_LINE' && !loading">
      <div class="d-flex">
        <span>Line Thickness:</span>
        <span v-if="data.styles && data.styles.line_size">
          <el-input-number
            v-model="data.styles.line_size"
            controls-position="right"
            :min="1"
            :max="100"
            @change="saveSettings()"
          ></el-input-number>
        </span>
      </div>
      <div class="d-flex">
        <span>Line Colour</span>
        <span v-if="data.styles && data.styles.line_color">
          <el-color-picker
            v-model="data.styles.line_color"
            show-alpha
            @change="saveSettings()"
          ></el-color-picker>
        </span>
      </div>
    </div>
    <div v-else>
      <div class="d-flex justify-content-between">
        <span>Field</span>
        <span>
          <span>{{ data.label }}</span>
          - {{ data.input_type }}
        </span>
      </div>
      <!-- 
      <div class="d-flex">
        <span>Visibility</span>
        <span>
          <el-switch v-model="data.visibility"></el-switch>
        </span>
      </div>-->
      <div
        class="d-flex justify-content-between"
        v-if="
          field.input_type != 'PARAGRAPH' &&
          field.input_type != 'HEADING' &&
          field.input_type != 'SINGLE_LINE_CONTENT' &&
          field.input_type != 'HTML_CONTENT' &&
          field.input_type != 'DIVISION' &&
          field.input_type !== 'ICON' &&
          data.styles
        "
      >
        <span>Label Position</span>
        <span>
          <el-select v-model="data.styles.labelStyle" @change="saveSettings()">
            <el-option
              v-for="(label, index) of labelStyles"
              :value="label.value"
              :label="label.name"
              :key="index"
            >
              <span style="float: left">{{ label.name }}</span>
              <!-- <span style="float: right; color: #8492a6; font-size: 13px" v-html="border.demo"></span> -->
            </el-option>
          </el-select>
        </span>
      </div>
      <div
        class="d-flex justify-content-between"
        v-if="
          field.input_type != 'PARAGRAPH' &&
          field.input_type != 'HEADING' &&
          field.input_type != 'SINGLE_LINE_CONTENT' &&
          field.input_type != 'HTML_CONTENT' &&
          field.input_type != 'DIVISION' &&
          field.input_type != 'CHECKBOX' &&
          field.input_type != 'YES_OR_NO' &&
          field.input_type != 'RADIO' &&
          field.input_type != 'IMAGE' &&
          field.input_type != 'ACTION_BUTTON' &&
          field.input_type !== 'ICON' &&
          data.styles &&
          data.styles.labelStyle !== 'right'
        "
      >
        <span>Label Alignment</span>
        <span>
          <el-select
            v-model="data.styles.labelAlignments"
            @change="saveSettings()"
          >
            <el-option
              v-for="(label, index) of labelAlignments"
              :value="label.value"
              :label="label.name"
              :key="index"
            >
              <span style="float: left">{{ label.name }}</span>
              <!-- <span style="float: right; color: #8492a6; font-size: 13px" v-html="border.demo"></span> -->
            </el-option>
          </el-select>
        </span>
      </div>
      <div
        class="d-flex justify-content-between"
        v-if="
          field.input_type != 'PARAGRAPH' &&
          field.input_type != 'HTML_CONTENT' &&
          field.input_type != 'MULTI_LINE_TEXT' &&
          field.input_type != 'CHECKBOX_GROUP' &&
          field.input_type != 'MULTI_SELECT' &&
          field.input_type != 'RADIO_BUTTON_GROUP' &&
          field.input_type != 'LIST' &&
          field.input_type != 'DATE' &&
          field.input_type != 'TIME' &&
          field.input_type != 'TIME_RANGE' &&
          field.input_type != 'DATE_TIME' &&
          field.input_type != 'WEEKDAYS' &&
          field.input_type != 'DATE_TIME_RANGE' &&
          field.input_type != 'DATE_RANGE' &&
          field.input_type != 'YES_OR_NO' &&
          field.input_type != 'CHECKBOX' &&
          field.input_type != 'DIVISION' &&
          field.input_type != 'RADIO' &&
          field.input_type != 'PHONE_COUNTRY_CODE' &&
          field.input_type != 'VIDEO' &&
          field.input_type != 'ACTION_BUTTON' &&
          field.input_type != 'IMAGE' &&
          field.input_type !== 'ICON' &&
          data.styles
        "
      >
        <span>Input Text Alignment</span>
        <span>
          <el-select
            v-model="data.styles.inputTextAlignments"
            @change="saveSettings()"
          >
            <el-option
              v-for="(label, index) of inputTextAlignments"
              :value="label.value"
              :label="label.name"
              :key="index"
            >
              <span style="float: left">{{ label.name }}</span>
              <!-- <span style="float: right; color: #8492a6; font-size: 13px" v-html="border.demo"></span> -->
            </el-option>
          </el-select>
        </span>
      </div>
    </div>
    <div class="d-flex justify-content-between" v-if="data.styles">
      <span>Border Size</span>
      <span>
        <el-input-number
          v-model="data.styles.border_size"
          controls-position="right"
          :min="0"
          :max="100"
          @change="saveSettings()"
          style="width: 220px"
        ></el-input-number>
      </span>
    </div>
    <div class="d-flex justify-content-between" v-if="data.styles">
      <span>Border Type</span>
      <span>
        <el-select v-model="data.styles.border_type">
          <el-option
            v-for="(border, index) of borderTypes"
            :value="border.value"
            :label="border.name"
            :key="index"
            @change="saveSettings()"
          >
            <span style="float: left">{{ border.name }}</span>
            <span
              style="float: right; color: #8492a6; font-size: 13px"
              v-html="border.demo"
            ></span>
          </el-option>
        </el-select>
      </span>
    </div>
    <div
      class="d-flex justify-content-between"
      v-if="data.input_type == 'ICON'"
    >
      <span>Background Shape</span>
      <el-select
        v-model="data.styles.selectedShape"
        placeholder="Select Shape to apply"
        @change="saveSettings"
        clearable
        style="margin-left: 10px"
      >
        <el-option
          v-for="shape in shapeOptions"
          :key="shape.value"
          :label="shape.label"
          :value="shape.value"
        ></el-option>
      </el-select>
    </div>
    <!-- tharuni Start -->
    <div
      class="d-flex justify-content-between"
      v-if="
        field.input_type != 'DIVISION' &&
        field.input_type != 'HEADING' &&
        data.styles &&
        field.input_type !== 'ICON' &&
        data.styles.font
      "
    >
      <span>Font Size {{ data.styles.font.font_size }}</span>
      <span>
        <el-select
          v-model="data.styles.font.font_size"
          @change="saveSettings()"
          @clear="resetFontSize"
          clearable
        >
          <el-option
            v-for="item in fontSizes"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            >{{ item.label }}
          </el-option>
        </el-select>
      </span>
    </div>
    <!-- bold -->
    <div
      class="d-flex"
      v-if="field.input_type != 'DIVISION' && field.input_type !== 'ICON'"
    >
      <span>Bold & Italic</span>
      <div class="d-flex ml-5">
        <span>
          <el-button
            type="plain"
            @click="makeTextBold"
            :class="
              this.data.styles &&
              this.data.styles.font &&
              this.data.styles.font.style &&
              (this.data.styles.font.style == 1 ||
                this.data.styles.font.style == 3)
                ? 'text-bold text-editot-btn-active'
                : 'text-bold text-editot-btn'
            "
            >B
          </el-button>
        </span>
        <span>
          <el-button
            type="plain"
            @click="makeTextItalic"
            :class="
              this.data.styles &&
              this.data.styles.font &&
              this.data.styles.font.style &&
              (this.data.styles.font.style == 2 ||
                this.data.styles.font.style == 3)
                ? 'text-italic text-editot-btn-active'
                : 'text-italic text-editot-btn'
            "
            >It
          </el-button>
        </span>
      </div>
    </div>
    <div class="d-flex" v-if="data.styles">
      <span>Label Background</span>
      <span class="ml-5">
        <el-color-picker
          v-model="data.styles.background"
          @change="saveSettings()"
          show-alpha
        ></el-color-picker>
      </span>
    </div>
    <div class="d-flex" v-if="data.styles">
      <span>Border Color</span>
      <span class="ml-5">
        <el-color-picker
          @change="saveSettings()"
          v-model="data.styles.border_color"
          show-alpha
        ></el-color-picker>
      </span>
    </div>
    <!-- tharuni end -->
    <!-- <div class="d-flex">
        <span>Input Background</span>
        <span>
          <el-color-picker @change="saveSettings()" v-model="data.styles.input_background" show-alpha></el-color-picker>
        </span>
      </div> -->
    <div
      class="d-flex"
      v-if="
        field.input_type != 'DIVISION' &&
        field.input_type != 'ICON' &&
        data.styles
      "
    >
      <span>Label Colour</span>
      <span class="ml-5">
        <el-color-picker
          @change="saveSettings()"
          v-model="data.styles.label_color"
          show-alpha
        ></el-color-picker>
      </span>
    </div>
    <div class="d-flex justify-content-between">
      <span>Apply above Styles To</span>
      <span style="margin-left: 5px">
        <el-select
          v-model="selectedFields"
          @change="applyFieldStyles"
          multiple
          collapse-tags
        >
          <el-option :value="'All'" :label="'Select All'"></el-option>
          <el-option
            v-for="(option, index) of fieldsData"
            :value="option.key"
            :label="option.label"
            :key="index"
          ></el-option>
          <el-option :value="null" :label="'None'"></el-option>
        </el-select>
      </span>
    </div>
  </section>
</template>

<script>
import { bus } from "../../../main";
export default {
  name: "templates-formComponents-FieldSettings",
  props: ["field", "fieldsData"],
  data() {
    return {
      shapeOptions: [
        { label: "Box Background", value: "rectangle" },
        { label: "Rounded Background", value: "circle" },
      ],
      activeName: "1",
      borderSize: 0,
      borderColor: "",
      settings: [
        {
          title: "",
        },
      ],
      selectedFields: [],
      previousSelectedFields: [],
      isDataTableField: "",
      borderTypes: [
        {
          name: "None",
          value: "none",
          demo: "<span style='border:none;'>abc</span>",
        },
        {
          name: "Fill",
          value: "solid",
          demo: "<span style='border:solid;'>abc</span>",
        },
        {
          name: "Dotted",
          value: "dotted",
          demo: "<span style='border:dotted;'>abc</span>",
        },
        {
          name: "Dashed",
          value: "dashed",
          demo: "<span style='border:dashed;'>abc</span>",
        },
        {
          name: "double",
          value: "Double",
          demo: "<span style='border:double;'>abc</span>",
        },
        {
          name: "3D Border groove",
          value: "groove",
          demo: "<span style='border:groove;'>abc</span>",
        },
        {
          name: "3D Border ridge",
          value: "ridge",
          demo: "<span style='border:ridge;'>abc</span>",
        },
        {
          name: "3D Border outset",
          value: "outset",
          demo: "<span style='border:outset;'>abc</span>",
        },
        {
          name: "3D Border inset",
          value: "inset",
          demo: "<span style='border:inset;'>abc</span>",
        },
      ],
      // Tharuni Start
      fontSizes: [
        {
          value: 8,
          label: 8,
        },
        {
          value: 10,
          label: 10,
        },
        {
          value: 12,
          label: 12,
        },
        {
          value: 14,
          label: 14,
        },
        {
          value: 16,
          label: 16,
        },
        {
          value: 18,
          label: 18,
        },
        {
          value: 20,
          label: 20,
        },
        {
          value: 22,
          label: 22,
        },
        {
          value: 24,
          label: 24,
        },
        {
          value: 26,
          label: 26,
        },
        {
          value: 28,
          label: 28,
        },
        {
          value: 30,
          label: 30,
        },
        {
          value: 32,
          label: 32,
        },
        {
          value: 36,
          label: 36,
        },
        {
          value: 48,
          label: 48,
        },
        {
          value: 56,
          label: 56,
        },
        {
          value: 72,
          label: 72,
        },
        {
          value: 84,
          label: 84,
        },
        {
          value: 96,
          label: 96,
        },
      ],
      // Tharuni End
      labelStyles: [
        {
          name: "On Top",
          value: "top",
          // demo: "<span style='border:outset;'>abc</span>"
        },
        {
          name: "Before Field",
          value: "right",
          // demo: "<span style='border:inset;'>abc</span>"
        },
      ],
      labelAlignments: [
        {
          name: "To Left",
          value: "left",
        },
        {
          name: "To Center",
          value: "center",
        },
        {
          name: "To Right",
          value: "right",
        },
      ],
      inputTextAlignments: [
        {
          name: "Left",
          value: "left",
        },
        {
          name: "Center",
          value: "center",
        },
        {
          name: "Right",
          value: "right",
        },
      ],
      loading: false,
      data: {},
    };
  },
  mounted() {
    this.loading = true;
    this.data = this.field;
    if (this.data && this.data.styles) {
      if (!this.data.styles.font) {
        this.$set(this.data.styles, "font", {
          name: "Helvetica",
          size: 16,
          style: 0,
          color: "#000000",
        });
      }
      let styles = this.data.styles;
      if (!styles.line_size) {
        styles = { ...styles, ...{ line_size: 1 } };
      }
      if (!styles.line_color) {
        styles = { ...styles, ...{ line_color: "#000000" } };
      }
      if (!styles.background) {
        styles = { ...styles, ...{ background: "" } };
      }
      if (!styles.border_type) {
        styles = { ...styles, ...{ border_type: "" } };
      }
      if (!styles.border_size) {
        styles = { ...styles, ...{ border_size: "" } };
      }
      if (!this.data.styles.selectedShape) {
        this.$set(this.data.styles, "selectedShape", "");
      }
      this.$set(this.data, "styles", styles);
    } else {
      //By default every field don't have style property. Have to assign empty style.
      let styles = {
        font: {
          name: "Helvetica",
          size: 16,
          style: 0,
          color: "#000000",
        },
        line_size: "",
        line_color: "",
        background: "",
        border_size: "",
        border_color: "",
        border_type: "",
        selectedShape: "",
      };
      this.$set(this.data, "styles", styles);
    }
    this.loading = false;
  },
  methods: {
    applyFieldStyles() {
      if (this.selectedFields.includes("All")) {
        this.selectedFields = this.fieldsData.map((field) => field.key);
      } else if (this.selectedFields.includes(null)) {
        this.selectedFields = [];
      }
      const deselectedFields = this.previousSelectedFields.filter(
        (field) => !this.selectedFields.includes(field)
      );
      deselectedFields.forEach((deselectedField) => {
        const deselected = this.fieldsData.find(
          (field) => field.key === deselectedField
        );
        if (deselected) {
          deselected.styles = {};
        }
      });
      this.selectedFields.forEach((selectedField) => {
        const selected = this.fieldsData.find(
          (field) => field.key === selectedField
        );
        if (selected) {
          selected.styles = {
            ...this.data.styles,
          };
        }
      });
      this.previousSelectedFields = [...this.selectedFields];
    },

    saveSettings() {
      bus.$emit("sendLineSizeData", this.data.styles.line_size);
      for (const key in this.data.styles) {
        if (
          key === "background" ||
          key === "border_size" ||
          key === "border_color" ||
          key === "border_type" ||
          key === "label_color" ||
          key === "input_background" ||
          key === "labelStyle" ||
          key === "font_size" ||
          key === "style" ||
          key === "line_size" ||
          key === "line_color"
        ) {
          if (key === "labelStyle") {
            console.log(this.data.styles[key]);
          }
          this.data.styles[key] === null
            ? delete this.data.styles[key]
            : this.data.styles[key];
        }
      }
    },
    resetFontSize() {
      this.data.styles.font.font_size = 14;
      setTimeout(() => {
        this.data.styles.font.font_size = null;
      }, 0);
    },
    // Tharuni Start
    makeTextItalic() {
      if (this.data.styles.font.style == 2) {
        this.data.styles.font.style = 0;
      } else if (this.data.styles.font.style == 3) {
        this.data.styles.font.style = 1;
      } else if (this.data.styles.font.style == 1) {
        this.data.styles.font.style = 3;
      } else {
        this.data.styles.font.style = 2;
      }
    },
    makeTextBold() {
      if (this.data.styles.font.style == 1) {
        this.data.styles.font.style = 0;
      } else if (this.data.styles.font.style == 3) {
        this.data.styles.font.style = 2;
      } else if (this.data.styles.font.style == 2) {
        this.data.styles.font.style = 3;
      } else {
        this.data.styles.font.style = 1;
      }
    },

    // Tharuni End
  },
};
</script>

<style lang="scss" scoped>
.text-editot-btn-active {
  background: rgb(214, 237, 250) !important;
}
.field-styling-drawer {
  padding: 0em 1.5em;
  .d-flex {
    margin-bottom: 1em;
    & > span:first-child {
      min-width: 120px;
    }
  }
}
</style>
